<template>
  <div class="background_basic">          
    <v-card class="ma-3">
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">contact_mail</v-icon>권한
        </v-toolbar-title>          
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>        
        <v-spacer></v-spacer>
      </v-toolbar>  
      <v-divider></v-divider>
    
      <v-toolbar dense flat color="white">
        <v-text-field
          v-model="UserInfo.UserId"
          class="ml-0 mt-5 mb-n2"   
          label="사용자 ID"
          disabled                  
          dense       
        ></v-text-field>

        <v-text-field
          v-model="UserInfo.UserNm"
          class="ml-2 mt-5 mb-n2"   
          label="이름"   
          disabled                     
          dense            
        ></v-text-field>

        <v-text-field
          v-model="UserInfo.PartNm"
          class="ml-2 mt-5 mb-n2"   
          label="부서"                        
          disabled
          dense            
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="ma-4" style="overflow:auto; height:calc(100vh - 220px); background-color:#ffffff">            
        <v-row class="ma-2">
          <v-col cols="12" sm="12" md="4"  v-for="(item, i) in zAlarmInfo" v-bind:key="i" >
            <v-card outlined flat class="ma-0">
              <v-card-title class="mt-n2 mb-n5">
                <p class="subtitle-1">{{item.cEventNm}}</p>
                <v-spacer></v-spacer>
                <v-switch color="grey darken-2" class="mt-0 ml-2" v-model="item.bCheck" :disabled="!ProgramPermissionInfo.okU"></v-switch>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12">            
            <v-card-title class="mt-n5">
              <v-spacer></v-spacer>
              <v-btn :disabled="!ProgramPermissionInfo.okU || loading" width="150" dark color="blue" @click="SetAlarmInfo()"  class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">save</v-icon>저장
              </v-btn>
            </v-card-title>
          </v-col>
        </v-row>          
      </div>    
    </v-card>       
    
    
    
  </div>
</template>

<script>
import {mapState} from "vuex";
import BasicInfo from "../BasicInfo.js";
import axios from "axios";
import EventBus from '@/eventBus.js';

export default {
  computed: {
     ...mapState(["isLogin", "UserInfo", "ProgramPermissionInfo",]),    
  },
  created() {
    this.initialize();
  },
  data: () => ({
    loading : false,
    zAlarmInfo: [],
  }),

  methods: {
    initialize() {      
      this.zAlarmInfo = [];

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId
      };
      axios
        .post(BasicInfo.UIL_API + "GetEventInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.zAlarmInfo = res.data.Result;
            for (var i = 0; i < this.zAlarmInfo.length; i++) {
              this.zAlarmInfo[i].bCheck = this.zAlarmInfo[i].cUse == "Y"; 
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },
    SetAlarmInfo() {      
      for (var i = 0; i < this.zAlarmInfo.length; i++) {
        this.zAlarmInfo[i].cUse = "N"
        if (this.zAlarmInfo[i].bCheck) {
          this.zAlarmInfo[i].cUse = "Y"
        }
      }

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,  
        zAlarmInfo : this.zAlarmInfo,      
      };
      axios
        .post(BasicInfo.UIL_API + "SetAlarmInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            //
             EventBus.$emit("onShowMsg",false, "저장되었습니다.");

          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },
  },
}
</script>

<style>

</style>